import React from "react";
import store from "../store";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import hideOnScroll from '../components/hideonscroll'
import MenuIcon from '@material-ui/icons/Menu';

import { NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { Link } from "react-router-dom";

import TopBar from '../components/TopBar';
import Paper from '@material-ui/core/Paper';

import { Redirect } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { FIREBASE } from "../config/firebase";
import Divider from '@material-ui/core/Divider';
import * as STATIC from '../config/static';
import _ from 'lodash'
import LinkIcon from '@material-ui/icons/Link';
if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE);
}
function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}
function guid(len=4) {
        var buf = [],
            chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
            charlen = chars.length,
            length = len || 32;

        for (var i = 0; i < length; i++) {
            buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
        }

        return buf.join('');
    }
function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function validatePhone(phone) {
  var re = /^([0-9]{6,20})$/;
  return re.test(phone);
};

const firestore = firebase.firestore();
//let IS_NAMING = window.location.hostname.toLowerCase()=='www1.namethegiraffe.com'?true:false;
//IS_NAMING = true;


var _this;

class HomePage extends React.Component {
  state = {
    viewDidLoad: false,
  };
  constructor() {
    super();
    _this = this;
    this.state={links:[],loading:false}

  }
  updateDimensions() {
    //console.log(window.innerWidth);
    // _this.setState({
    //   innerWidth: window.innerWidth,
    //   innerHeight: window.innerHeight
    // });
  }
  componentDidMount() {

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    let links = localStorage.getItem('links');
    if(links)
    {
      links = JSON.parse(links);
      this.setState({links});
    }



    // this.setState({
    //   innerWidth: window.innerWidth,
    //   innerHeight: window.innerHeight
    // });
    //window.addEventListener("resize", this.updateDimensions);
    //this.loadBible();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    _this.setState({ wWidth: window.innerWidth, wHeight: window.innerHeight });
  }
  handleChange = name => event => {
    //event.preventDefault();
    _this.setState({ [name]: event.target.value });
  };

  getName(fullname)
  {
    return _.last(fullname.split(' '));
  }
async submitLink(e)
{
  e.preventDefault();
  let {link,links,loading} = this.state;
  if(loading)
  return;
await  this.setState({loading:true})
  if(validURL(link))
  {

let key = guid();
let doc = await firebase.firestore().collection('links').doc(key).get();
if(doc.exists)
{
   key = guid();
}
let item = {link:link,short:key,created:(new Date().getTime())};
let rel = await firebase.firestore().collection('links').doc(key).set(item);
console.log(rel);
links.push(item);

  localStorage.setItem('links', JSON.stringify(links));
      STATIC.NotificationManager.success('Đã tạo link rút gọn!');
      this.setState({link:'',links});

  }
  else {
    STATIC.NotificationManager.error('Vui lòng nhập URL hợp lệ!');
  }
  await  this.setState({loading:false})
}

  render() {
    const { classes,t } = this.props;
    const { loading, wWidth, wHeight,link,links } = this.state;
    let HC = STATIC.calSize;
    let width = STATIC.DWIDTH;
    if (wWidth < STATIC.DWIDTH) {
      width = wWidth;
    }


    let fLarge = width * 0.06;
    let fTitle = width * 0.04;
    let fNormal = width * 0.03;
    let fSmall = width * 0.02;
let fSmaller = width * 0.015;

    //console.log(ACCOUNT);
    return (
      <div className={classes.root}>

      <div className={classes.middle} style={{verticalAlign:'middle'}}>
        <div className={classes.square} style={{display:'flex',justifyContent:'center',padding:HC(20,width),alignItems:'center',flexDirection:'column',backgroundColor:STATIC.MyStyle.BG_COLOR,borderRadius:HC(20,width)}}>

          <div style={{textAlign:"center",color:STATIC.MyStyle.TEXT_COLOR,fontSize:width*0.1}}>GIA.RE</div>
            <Grid container >

              <Grid item xs={12} style={{ padding: '0px 0px 0px ' + HC(10, width) + 'px', marginBottom: HC(15, width) }}>
                <TextField
                  label="" variant="outlined"
                  value={link}
                  required
                  placeholder={'http://example.com/'}
                  onChange={(e)=>{ this.setState({link:e.target.value})}} fullWidth
                  style={{backgroundColor:'white',borderRadius:HC(20,width)}}
                  inputProps={{ style: {textAlign: 'center'} }}
                   />
                 </Grid>
                 <Grid item xs={12} style={{ padding: '0px 0px 0px ' + HC(10, width) + 'px', marginBottom: HC(15, width),justifyContent:'center',display:'flex' }}>
                   <ButtonBase onClick={e=>this.submitLink(e)} focusRipple style={{ color: "white", position: 'relative', display: 'flex',borderRadius:HC(10,width), padding: HC(10, width) + 'px ' + HC(10, width) + 'px', backgroundColor: '#6d22c6' }} color="primary" size="large" variant="contained"  >
                     {loading?<CircularProgress />:<div style={{ fontSize: fNormal }}> Rút gọn</div>}
                   </ButtonBase>
              </Grid>
            </Grid>
            {links.length>0?<div>
              <div style={{height:HC(1,width),backgroundColor:'#c9beec',width:width*0.7,marginBottom:HC(10,width),marginTop:HC(10,width)}}/>
              <div style={{textAlign:"center",color:STATIC.MyStyle.TEXT_COLOR,fontSize:fNormal}}>Link đã tạo</div>
              <div style={{height:HC(1,width),backgroundColor:'#c9beec',width:width*0.7,marginBottom:HC(10,width),marginTop:HC(10,width)}}/>
              <div style={{maxHeight:900}}>
              {links.map((item,index)=>{
                return (<div style={{display:'flex',flexDirection:'row'}}>
                    <div >
                      <div style={{color:'white',fontSize:fNormal,display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <LinkIcon style={{fontSize:fNormal,marginRight:HC(10,width)}} />{"https://gia.re/"+item.short}
                      </div>
                      <div style={{color:'gray',fontSize:fSmaller}}>
                        Link gốc: {item.link}
                      </div>
                  </div>
                    <div><CopyToClipboard text={"https://gia.re/"+item.short}
          onCopy={() => STATIC.NotificationManager.success('Đã copy')}><ButtonBase focusRipple style={{ color: "white",marginLeft:HC(10,width), position: 'relative', display: 'flex',borderRadius:HC(10,width), padding: HC(10, width) + 'px ' + HC(10, width) + 'px', backgroundColor: '#855fd0' }} color="primary" size="large" variant="contained"  >
                      <div style={{ fontSize: fSmall }}> Copy</div>
                    </ButtonBase></CopyToClipboard></div>
                </div>)
              })}
              </div>
            </div>:false}
            <div style={{height:HC(1,width),backgroundColor:'white',width:width*0.7,marginBottom:HC(10,width),marginTop:HC(10,width)}}/>
            <div style={{color:'white'}}>
              Copyright © 2You LLC - 2020
            </div>
        </div>
          </div>





      </div>
    );
  }
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(STATIC.styles)(STATIC.translate('common')(HomePage));
