export const FIREBASE = {
  apiKey: "AIzaSyDQSVJQG15xmeQTS_uRT0GjJ_Ysju6Xqxo",
  authDomain: "giare-700ce.firebaseapp.com",
  databaseURL: "https://giare-700ce.firebaseio.com",
  projectId: "giare-700ce",
  storageBucket: "giare-700ce.appspot.com",
  messagingSenderId: "85270936583",
  appId: "1:408991227140:web:9ecc30ddc4bd2529ba4451",
    persistance:true
}
