import React from "react";
import store from "../store";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import "react-intl-tel-input/dist/main.css";
import IntlTelInput from "react-intl-tel-input";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import hideOnScroll from '../components/hideonscroll'
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { Link } from "react-router-dom";

import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import FlightSearchForm from '../components/FlightSearchForm';
import SearchResults from '../components/SearchResults';
import CheckOutInfo from '../components/CheckOutInfo';
import Paper from '@material-ui/core/Paper';

import { Redirect } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { FIREBASE } from "../config/firebase";
import Divider from '@material-ui/core/Divider';
import * as STATIC from '../config/static';
import _ from 'lodash';
import { Helmet } from "react-helmet";
import Modal from 'react-responsive-modal';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import FlightLandIcon from '@material-ui/icons/FlightLand';
if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE);
}
function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function validatePhone(phone) {
  var re = /^([0-9]{6,20})$/;
  return re.test(phone);
};


const firestore = firebase.firestore();
//let IS_NAMING = window.location.hostname.toLowerCase()=='www1.namethegiraffe.com'?true:false;
//IS_NAMING = true;


var _this;

class HomePage2 extends React.Component {
  state = {
    viewDidLoad: false,
  };
  constructor() {
    super();
    _this = this;
    this.state = {loading:false,searchData:null,flight:null,first:false}

  }
  updateDimensions() {
    //console.log(window.innerWidth);
    // _this.setState({
    //   innerWidth: window.innerWidth,
    //   innerHeight: window.innerHeight
    // });
  }
  componentDidMount() {

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    //this.loadSearch('Viet Nam')
    //this.loadFlights();


    // this.setState({
    //   innerWidth: window.innerWidth,
    //   innerHeight: window.innerHeight
    // });
    //window.addEventListener("resize", this.updateDimensions);
    //this.loadBible();
  }

  async loadFlights(data){
    //console.log(data);

  await _this.setState({loading:true,searchData:null,first:false,query:data});
    let res = await STATIC.getSessionRapid(data);
    //  console.log(res);
    if(res&&res.status==1)
    {
      let session = res.location.split('/');
      session = session[session.length-1];
      //console.log(session);
      if(session)
      _this.pollResults(session);
      else {
        await _this.setState({loading:false});
      }
    }
    else {
      _this.setState({loading:false});
    }
  }
  async pollResults(session){
    let query = {sortType:'price',sortOrder:'asc',pageIndex:0,pageSize:30};
    let res = await STATIC.getDataRapid(STATIC.API_RAPID_SEARCH+session, query);
      //let res = await STATIC.postJSONData(STATIC.API_TICKET_SEARCH,{query,location:session});
      if(res&&res.SessionKey)
      {
        //console.log(res.Status);
        if(res.Status=='UpdatesPending')
        {
          if(!_this.state.first)
          _this.setState({first:true,searchData:res});
          ///....
          setTimeout(()=>{
            _this.pollResults(session);
          },2000);

        }
        else
        {
          _this.setState({loading:false,searchData:res});
        }

      }
      else {
        _this.pollResults(session);
      }
  }
  showBuyTicket(flight)
  {
    flight.Info = _this.state.query;
    console.log(flight);
    _this.setState({flight})
  }



  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    _this.setState({ wWidth: window.innerWidth, wHeight: window.innerHeight });
  }
  handleChange = name => event => {
    //event.preventDefault();
    _this.setState({ [name]: event.target.value });
  };



  render() {
    const { classes, t } = this.props;
    const { loading, wWidth, wHeight,searchData,flight } = this.state;
    let width = STATIC.DWIDTH;
    if (wWidth < STATIC.DWIDTH) {
      width = wWidth;
    }
    let fLarge = width * 0.06;
    let fTitle = width * 0.04;
    let fNormal = width * 0.03;
    let fSmall = width * 0.02;
    let fSmaller = width * 0.015;
    let HC = STATIC.calSize;

    /*
    <div
      data-skyscanner-widget="FlightSearchWidget"
      data-associate-id="vegiare"
      data-locale="vi-VN"
      data-market="VN"
      data-currency="VND"
      data-colour="#000000"
      data-multi-city-max-flights-number="0"
      data-flights-cabin-classes="false"
    ></div>
    <script src="https://widgets.skyscanner.net/widget-server/js/loader.js" async></script>
    */
    //console.log(ACCOUNT);
    return (
      <div className={classes.root} >
        <div style={{ backgroundColor: STATIC.MyStyle.BG_TRANS_FULL, position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }} />

        <div className={classes.middle}>

          <TopBar width={width} history={this.props.history} />

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', color: STATIC.MyStyle.TEXT_COLOR,marginTop:HC(40,width) }}>
            <div style={{ backgroundColor: STATIC.MyStyle.BG_TRANS, display: 'flex', flexDirection: 'row', alignItems: 'center', maxWidth: STATIC.DWIDTH, width: width, padding: '0px ' + HC(10, width) + 'px' }}>
                <FlightSearchForm width={width-HC(10, width)*2} onSubmit={this.loadFlights} />
            </div>
          </div>
          {loading?<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', color: STATIC.MyStyle.TEXT_COLOR,marginTop:HC(40,width) }}>
            <div style={{ backgroundColor: STATIC.MyStyle.BG_TRANS, display: 'flex', flexDirection: 'row', alignItems: 'center', maxWidth: STATIC.DWIDTH, width: width, padding: '0px ' + HC(10, width) + 'px' }}>
              <div style={{ backgroundColor: STATIC.MyStyle.BG_TRANS, color: STATIC.MyStyle.TEXT_COLOR, display: 'flex',flexDirection:'column', alignItems: 'center', maxWidth: STATIC.DWIDTH, width: width, padding: HC(20, width) + 'px ' + HC(10, width) + 'px' }}>
            <div>Đang tìm chuyến bay cho bạn</div>
              <div><CircularProgress color="inherit" size={20} /></div>
          </div>
        </div>
      </div>:false}
          {searchData?<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', color: STATIC.MyStyle.TEXT_COLOR,marginTop:HC(40,width) }}>
            <div style={{ backgroundColor: STATIC.MyStyle.BG_TRANS, display: 'flex', flexDirection: 'row', alignItems: 'center', maxWidth: STATIC.DWIDTH, width: width, padding: '0px ' + HC(10, width) + 'px' }}>
                <SearchResults searchData={searchData} width={width-HC(10, width)*2} loading={loading} showBuyTicket={this.showBuyTicket}  />
            </div>
          </div>:false}


          <div className={classes.square} style={{ maxWidth: width, backgroundColor: 'rgba(0,0,0,0.8)' }}>
            <div style={{ padding: (HC(40, STATIC.DWIDTH, width) + 'px ' + HC(20, width) + 'px'), flexGrow: 1, position: 'relative' }}>
              <div>
                <Grid container direction="column" alignItems="center" justify="center" >

                </Grid>
              </div>

            </div>
          </div>
          <Footer width={width} />
        </div>
        {flight?<Modal styles={{overlay:{padding:0}, modal: {position:'fixed',top:0,left:0,right:0,bottom:0,padding:0,maxWidth:wWidth,display:'flex',flexDirection:'column',  background: 'none', boxShadow: 'none',display:'flex'  } }} showCloseIcon={false} focusTrapped={false} open={flight!=null} onClose={() => this.setState({ flight: null })} center>
                <CheckOutInfo flight={flight} width={width-HC(10, width)*2} onClose={()=>this.setState({flight:null})} />

            </Modal>:false}
      </div>
    );
  }
}

HomePage2.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(STATIC.styles)(STATIC.translate('common')(HomePage2));
