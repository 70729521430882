import React from 'react';
import store from "../store";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import firebase from "firebase/app";
import { Link } from "react-router-dom";
import * as STATIC from '../config/static';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import SyncIcon from '@material-ui/icons/Sync';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add';
import Person from '@material-ui/icons/Person';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import "react-intl-tel-input/dist/main.css";
import IntlTelInput from "react-intl-tel-input";
import moment from 'moment';
import 'moment-lunar';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import "moment/locale/vi";
import _ from 'lodash'
import {
  DatePicker
} from '@material-ui/pickers';
import styled from 'styled-components';
moment.locale("vi");
const throttle = require('lodash.throttle');
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TopDiv = styled.div`
  top: -.375rem;
  position: absolute;
    width: .75rem;
    height: .375rem;
    overflow: hidden;
    &:after {
      right: -50%;
  bottom: 200%;
  left: -50%;
  position: relative;
      content: "";
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      transform: translateZ(0);
      border: .375rem solid #fff;
      border-radius: .75rem;
      box-shadow: inset 0 1px 3px 0 rgba(37,32,31,.3);
  }
`;
let _this = this;
class CheckOutInfo extends React.Component {

  constructor(props) {
    super(props)
    _this = this;
    let flight = _.cloneDeep(props.flight);
    flight.Passengers = {adults:[],
    childrens:[],
    infants:[]
  }
  _.range(parseInt(flight.Info.adults)).forEach(key=>{
    flight.Passengers.adults.push({fname:'',lname:'',genre:'male',id:''});
  });
  if(parseInt(flight.Info.childrens)>0)
  _.range(parseInt(flight.Info.childrens)).forEach(key=>{
    flight.Passengers.childrens.push({fname:'',lname:'',genre:'male',id:''});
  });
  if(parseInt(flight.Info.infants)>0)
  _.range(parseInt(flight.Info.infants)).forEach(key=>{
    flight.Passengers.infants.push({fname:'',lname:'',genre:'male',id:''});
  });
  console.log(flight);
    this.state = {
      flight,
      message:'',
      createAccount:'0',
      name:'',
      email:'',
      password:'',


    };
    console.log(props.searchData);
    //this.servicesWorker = _.throttle(this.servicesWorker, 500);
  }
  handleChange = name => event => {
    event.preventDefault();
    this.setState({
      [name]: event.target.value
    });
  }
  handleChangePerson = name => event => {
    event.preventDefault();
    this.setState({
      [name]: event.target.value
    });
  }
  // componentWillReceiveProps(props)
  // {
  //   if(props.loading!=this.state.loading)
  //   this.setState({loading:props.loading})
  // }


  onSelectFlag(number,countryData){
    //console.log(countryData.dialCode, number);
    _this.setState({ code: countryData.dialCode });
  }
  onPhoneNumberChange(status, value, countryData, number, id) {
    console.log(status, value, countryData, number, id);
    _this.setState({ code: countryData.dialCode, phone: value });
  }

  async confirmBuy(){
    let {flight,name,email,phone,payMethod,createAccount,password,code} = _this.state;
    if(!name||name.trim().length==0)
    {
      STATIC.NotificationManager.error('Bạn hãy nhập tên liên lạc!');
      return;
    }
    if(!email||email.trim().length==0||!STATIC.validateEmail(email.trim()))
    {
      STATIC.NotificationManager.error('Bạn hãy nhập địa chỉ email!');
      return;
    }
    if(! phone||phone.trim().length<6)
    {
      STATIC.NotificationManager.error('Bạn hãy nhập số điện thoại liên lạc!');
      return;
    }
    if(createAccount&&(! password||password.trim().length<6))
    {
      STATIC.NotificationManager.error('Mật khẩu phải có ít nhất 6 ký tự!');
      return;
    }
    if(!payMethod||payMethod.trim().length==0)
    {
      STATIC.NotificationManager.error('Hãy chọn phương thức thanh toán!');
      return;
    }
    let checked = true;
    Object.keys(flight.Passengers).forEach(key=>{
      flight.Passengers[key].forEach(p=>{
        if((!p.fname||p.fname.trim().length==0)||(!p.lname||p.lname.trim().length==0))
        checked = false;
      })
    })
    if(!checked)
    {
      STATIC.NotificationManager.error('Hãy nhập đủ thông tin hành khách!');
      return;
    }
    let fPhone = code+phone;
    let order = {name,email,phone:fPhone,payMethod,flight,created:(new Date().getTime())};
    let uid = '';
    if(createAccount)
    {
      let res = await STATIC.postFormData(STATIC.API_USER_CHECKANDCREATEUSER,{name,email,phone:fPhone,password,createAccount:createAccount=='2'?1:0})
      console.log(res);
      if(res&&res.status==1)
      {
        if(createAccount=='1')
        {
          if(res.uid&&res.password)
          {
            uid = res.uid;
          }
          else if(res.uid){
            STATIC.NotificationManager.error('Mật khẩu không đúng');
            return;
          }
          else {
            STATIC.NotificationManager.error('Email chưa đăng ký tài khoản!');
            return;
          }

        }
        else {
          if(res.uid){
            uid = res.uid;
          }
          else {
            STATIC.NotificationManager.error('Đã có tài khoản với email này!');
            return;
          }
        }
      }
    }
    order.uid=uid;

    console.log(order);


  }

  render() {

    const { classes,message,searchData } = this.props;
    const {loading,createAccount,flight,payMethod} = this.state;
    let resultData = searchData;
    let { oDialog } = this.state;
    let width = this.props.width;
    if (this.props.width > 980)
      width = 980;
    let HC = STATIC.calSize;
    let fVeryLarge = width * 0.12;
    let fLarge = width * 0.06;
    let fTitle = width * 0.04;
    let fNormal = width * 0.03;
    let fSmall = width * 0.02;
    let fSmaller = width * 0.015;
    let cWidth = 592;

      // let flight = resultData.Itineraries[0];
      // //console.log(flight.OutboundLegId);
      //  flight.OutboundLeg = _.find(resultData.Legs,{Id:flight.OutboundLegId});
      //   this.getLegsInfo(flight.OutboundLeg,resultData);
      //
      //
      // flight.InboundLeg = _.find(resultData.Legs,{Id:flight.InboundLegId});
      //
      // if(flight.InboundLeg)
      // {
      //     this.getLegsInfo(flight.InboundLeg,resultData);
      // }
      // flight.PricingOption = flight.PricingOptions[0];
      // console.log(flight);
      //PricingOption.Price QuoteAgeInMinutes DeeplinkUrl


    //  console.log(OutboundLeg);


    return (
      <div style={{flex:1,display:'flex',flexDirection:'column',overflow:'auto'}}>

        <div style={{  backgroundColor: 'white', position: 'relative', padding: HC(10, width) }}>
          <div style={{fontSize:fNormal,textAlign:'center'}}>Chi Tiết Đặt Vé</div>
          <div style={{position:'absolute',right:10,top:20}} onClick={this.props.onClose}><CloseIcon style={{fontSize:fNormal}} /></div>
        </div>
        <div style={{flex:1,backgroundColor:'#f1f2f8'}}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', color: STATIC.MyStyle.TEXT_COLOR,marginTop:HC(20,width) }}>
            <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', maxWidth: STATIC.DWIDTH, width: width, padding: '0px ' + HC(10, width) + 'px' }}>
              <Grid container direction='column'>
              <Grid  item xs={12}  >

                  <div style={{display:'flex',flexDirection:'row',minHeight:100}}>
                      <div  style={{width:width*0.8,flexDirection:'column',display:'flex',justifyContent:'center',backgroundColor:STATIC.MyStyle.BG_COLOR,padding:HC(10,width),borderRadius:HC(10,width),border:'2px solid '+STATIC.MyStyle.TEXT_COLOR,borderRight:0}}>
                              {flight.Bounds.map((bound,index2)=>{
                                let cName = '';
                                bound.Carriers.forEach(c=>{

                                  if(cName=='')
                                  cName = c.Name;
                                  else
                                  cName += '\n+ '+c.Name;
                                })
                                let sCode = '';
                                bound.Stops.forEach(s=>{

                                  if(sCode=='')
                                  sCode = s.Code;
                                  else
                                  sCode += ' '+s.Code;
                                })
                                return (<div key={index2} className={classes.centerFlex} style={{flexDirection:'row',marginTop:HC(index2>0?10:0,width)}}>
                                  <div style={{width:'20%',fontSize:fSmaller}}>

                                  {bound.Stops.length==0?<img src={bound.Carriers[0].ImageUrl}/>:cName}
                                </div>
                                <div className={classes.centerFlex}  style={{flexGrow:'1',flexDirection:'row'}}>
                                    <div style={{flexDirection:'column',display:'flex',width:'30%'}} >
                                        <div style={{fontSize:fNormal,textAlign:'right'}}>
                                          {moment(bound.Departure).format('HH:mm')}
                                        </div>
                                        <div style={{fontSize:fSmall,textAlign:'right'}}>
                                          {bound.OriginStation.Code}
                                        </div>
                                        <div style={{fontSize:fSmaller,textAlign:'right'}}>
                                          {bound.OriginStation.Name}
                                        </div>
                                    </div>
                                    <div className={classes.centerFlex} style={{flexDirection:'column',width:'40%',padding:'0px '+HC(10,width)+'px'}} >
                                      <div style={{fontSize:fSmall}}>
                                          {moment().startOf('day').add(bound.Duration, 'minutes').format('H[g] m[p]')}
                                      </div>
                                      <div className={classes.centerFlex} style={{position:'relative',flexDirection:'row',width:'100%'}}>
                                        <div>
                                          <FlightTakeoffIcon />
                                        </div>
                                        <div className={classes.centerFlex} style={{position:'relative',flexDirection:'row',height:5,flexGrow:1}}>
                                          <div style={{position:"absolute",top:2,left:0,right:0,height:2,backgroundColor:'gray',zIndex:0}}/>
                                          {bound.Stops.map((m,index3)=>{
                                            return <div key={index3} style={{width:8,height:8,borderRadius:4,backgroundColor:'red',zIndex:1,marginRight:HC(5,width)}}/>
                                          })}

                                        </div>
                                        <div>
                                          <FlightLandIcon />
                                        </div>

                                      </div>
                                      <div style={{fontSize:fSmall,textAlign:'center'}}>
                                          {bound.Stops.length==0? 'Bay thẳng':bound.Stops.length+' điểm dừng '+sCode}
                                      </div>
                                    </div>
                                    <div  style={{flexDirection:'column',display:'flex',width:'30%'}} >
                                        <div style={{fontSize:fNormal}}>
                                          {moment(bound.Arrival).format('HH:mm')}
                                        </div>
                                        <div style={{fontSize:fSmall,textAlign:'left'}}>
                                          {bound.DestinationStation.Code}
                                        </div>
                                        <div style={{fontSize:fSmaller,textAlign:'left'}}>
                                          {bound.DestinationStation.Name}
                                        </div>
                                    </div>
                                </div>
                                </div>)
                              })}
                      </div>

                      <div className={classes.centerFlex} style={{width:width*0.2,padding:HC(10,width),backgroundColor:STATIC.MyStyle.BG_COLOR,borderRadius:HC(10,width),border:'2px solid '+STATIC.MyStyle.TEXT_COLOR,flexDirection:'column'}}>
                          <div style={{fontSize:fSmall,fontWeight:'bold'}}>{STATIC.numberWithCommas(flight.PricingOption.Price.toFixed(0))} ₫</div>

                      </div>
                  </div>
              </Grid>

              <Grid item xs={12} style={{marginTop:HC(40,width)}}>
                  <div style={{fontSize:fNormal}}>Thông Tin Liên Hệ</div>
                  <div style={{marginTop:HC(10,width)}}>
                    <Grid container >
                        <Grid item xs={12} sm={6} style={{ padding: '0px 0px 0px ' + HC(10, STATIC.DWIDTH, width) + 'px', marginBottom: 10 }}>
                          <TextField
                            label="Họ và tên" variant="outlined"
                            value={this.state.name}
                            required
                            onChange={_this.handleChange('name')} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ padding: '0px 0px 0px ' + HC(10, STATIC.DWIDTH, width) + 'px', marginBottom: 10 }}>
                          <TextField
                            label="Email" variant="outlined"
                            value={this.state.email}
                            required
                            onChange={_this.handleChange('email')} fullWidth />

                        </Grid>
                        <Grid item xs={12} sm={6} style={{ padding: '0px 0px 0px ' + HC(10, width) + 'px', marginBottom: 10 }}>
                          <IntlTelInput
                            preferredCountries = {['vn','us']}
                              key='phonefield'
                              placeholder="số điện thoại"
                              defaultCountry="vn"
                              css={["intl-tel-input", classes.tellInput]}
                              onPhoneNumberChange={this.onPhoneNumberChange}
                              onSelectFlag={this.onSelectFlag}
                              style={{ width: "100%" }}
                              separateDialCode={true}
                              autoFocus={false}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} style={{ padding: '0px 0px 0px ' + HC(10, width) + 'px', marginBottom: 10 }}>

                              <FormControl component="fieldset" className={classes.formControl}>
                  <RadioGroup aria-label="createAccount" name="createAccount" value={createAccount} onChange={this.handleChange('createAccount')}>
                    <FormControlLabel value={"2"} control={<Radio />} label={<div style={{fontSize:fSmall}}>Tạo tài khoản để quản lý đặt vé</div>} />
                    <FormControlLabel value={"1"} control={<Radio />} label={<div style={{fontSize:fSmall}}>Đã có tài khoản</div>} />
                    <FormControlLabel value={"0"} control={<Radio />} label={<div style={{fontSize:fSmall}}>Không chọn</div>} />


                  </RadioGroup>
                </FormControl>
                        </Grid>
                        {createAccount!='0'?<Grid item xs={12} sm={6} style={{ padding: '0px 0px 0px ' + HC(10, STATIC.DWIDTH, width) + 'px', marginBottom: 10 }}>

                          <TextField

                            label="Mật khẩu" variant="outlined"
                            value={this.state.password}
                            type='password'
                            onChange={ _this.handleChange('password')} fullWidth />

                        </Grid>:false}
                      </Grid>

                  </div>
              </Grid>
              <Grid item xs={12} style={{marginTop:HC(40,width)}}>
                  <div style={{fontSize:fNormal}}>Thông Tin Hành Khách</div>
                  {Object.keys(flight.Passengers).map((key)=>{
                    return (
                      <div key={key}>
                      {flight.Passengers[key].map((p,index)=>{
                      return (<div key={key+index} style={{marginTop:HC(10,width)}}>
                        <div style={{fontSize:fSmall}}>Hành khách {index+1} ({key=='adults'?'người lớn':key=='childrens'?'trẻ em':'em bé(dưới 2 tuổi)'})</div>
                          <Grid container >

                            <Grid item xs={12} sm={6} style={{ padding: '0px 0px 0px ' + HC(10, STATIC.DWIDTH, width) + 'px', marginBottom: 10 }}>
                              <TextField
                                label="Họ" variant="outlined"
                                value={flight.Passengers[key][index].lname}
                                required
                                onChange={(e)=>{flight.Passengers[key][index].lname=e.target.value; this.setState({})}} fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ padding: '0px 0px 0px ' + HC(10, STATIC.DWIDTH, width) + 'px', marginBottom: 10 }}>
                              <TextField
                                label="Tên" variant="outlined"
                                value={flight.Passengers[key][index].fname}
                                required
                                onChange={(e)=>{flight.Passengers[key][index].fname=e.target.value; this.setState({})}} fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: '0px 0px 0px ' + HC(10, STATIC.DWIDTH, width) + 'px', marginBottom: 10 }}>
                              <TextField
                                label="Số hộ chiếu (Không bắt buộc nếu bay trong nước)" variant="outlined"
                                value={flight.Passengers[key][index].id}
                                onChange={(e)=>{flight.Passengers[key][index].id=e.target.value; this.setState({})}} fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ padding: '0px 0px 0px ' + HC(10, STATIC.DWIDTH, width) + 'px', marginBottom: 10 }}>
                            <FormControl variant="outlined" required className={classes.formControl} style={{minWidth:120}}>
                                    <InputLabel >
                                      Giới tính
                                    </InputLabel>
                                    <Select
                                      value={flight.Passengers[key][index].genre}
                                      onChange={(e)=>{flight.Passengers[key][index].genre=e.target.value; this.setState({})}}
                                    >
                                      <MenuItem value={'male'}>Nam</MenuItem>
                                      <MenuItem value={'female'}>Nữ</MenuItem>
                                    </Select>
                                  </FormControl>
                                  </Grid>


                            </Grid>
                      </div>)
                    })}
                    </div>
                  )
                  })}
              </Grid>
              <Grid item xs={12} style={{marginTop:HC(40,width)}}>
                  <div style={{fontSize:fNormal}}>Phương thức thanh toán</div>
                  <div style={{marginTop:HC(10,width)}}>
                    <Grid container >

                      <Grid item xs={12} >
                    <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup aria-label="gender" name="gender1" value={payMethod} onChange={this.handleChange('payMethod')}>
          <FormControlLabel value="bank" control={<Radio />} label={<div style={{font:fTitle}}><AccountBalanceIcon style={{font:fTitle}}/> Chuyển khoản</div>} />
          <FormControlLabel value="paypal" control={<Radio />} label={<img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_200x51.png" style={{height:25,width:'auto'}} />} />
        </RadioGroup>
      </FormControl>
      </Grid>
      <Grid item xs={12} >
          {payMethod=='bank'?<div style={{textAlign:'center',color:'black',fontSize:fNormal}}>Vui lòng chuyển khoản ngay sau khi đặt chỗ.<br/>Vé có thể tăng nếu bạn không chuyển khoảng trong vòng 20phút</div>:false}
          {payMethod=='paypal'?<div style={{textAlign:'center',color:'black',fontSize:fNormal}}>Bạn sẽ được chuyển sang <b>PayPal</b> để thanh toán.<br/>Vé có thể tăng nếu bạn không thanh toán trong vòng 20phút</div>:false}
      </Grid>
      </Grid>
                  </div>
                  </Grid>
                  <Grid item style={{ marginTop: HC(40, width),marginBottom: HC(40, width),display:'flex',justifyContent:'center'}}>
                    <ButtonBase onClick={()=>this.confirmBuy()} focusRipple style={{ color: "white", position: 'relative', display: 'flex', padding: HC(10, width) + 'px ' + HC(20, width) + 'px', backgroundColor: 'black' }} color="primary" size="large" variant="contained">
                      <div style={{ fontSize: cWidth <= width ? fTitle : fTitle * 2 }}>Đặt Vé</div>
                    </ButtonBase>
                  </Grid>

            </Grid>
            </div>
          </div>
        </div>

    </div>



    );
  }
}
const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 }
];
CheckOutInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(STATIC.styles)(STATIC.translate('common')(CheckOutInfo));
